<template>
  <b-modal 
    id="deleted-success-modal" 
    no-close-on-backdrop 
    no-close-on-esc 
    hide-header 
    hide-footer 
    centered 
    v-model="modalShow"
    size="md"
  >

    <div class="d-flex flex-column align-items-center justify-center-center my-2">
      <feather-icon icon="CheckIcon" class="text-success mb-2" size="80" />
      <div class="text-center">
        <span>Grupo de Empresa {{ group }} excluído com sucesso!</span>
      </div>
      <div class="d-flex mt-2">
        <b-button variant="outline-success" @click="confirmAction">
          Ok!
        </b-button>
      </div>
    </div>

  </b-modal>
</template>

<script>
import { BModal, BButton, BRow, BCol } from 'bootstrap-vue';

export default {
  components: {
    BModal, BButton, BRow, BCol
  },

  props: {
    modalShow: {
      required: true,
      type: Boolean
    },
    group: {
      required: true,
      type: String
    }
  },

  data() {
    return {
      loading: false
    }
  },

  methods: {
    confirmAction() {
      this.loading = true
      this.$emit('confirm')
    }
  },
  watch: {
    modalShow() {
      this.loading = false
    }
  }
}

</script>