<template>
  <div
    :class="className"
  >
    <section :class="classNameSlotContainer">
      <slot />
    </section>

    <section
      class="action-buttons"
      :class="{
        'd-flex justify-content-start': leftButtons,
        'disable-margin-bottom': disableMarginBottomButton
      }"
    >
      <button
        :disabled="getDisableButtonSearch"
        type="button"
        class="btn custom-blue mr-2"
        @click="actionSearch"
      >
        <feather-icon
          icon="SearchIcon"
        />
        Pesquisar
      </button>

      <button
        :disabled="getDisableButtonClear"
        type="button"
        class="btn custom-outline-blue"
        @click="actionClear"
      >
        <feather-icon
          icon="XIcon"
        />
        Limpar
      </button>
    </section>

    <hr v-if="!disableHr">
  </div>
</template>

<script>
export default {
  props: {
    className: {
      type: String,
      default: '',
    },

    classNameSlotContainer: {
      type: String,
      default: '',
    },

    disableButtonSearch: {
      type: Boolean,
      default: false,
    },

    disableButtonClear: {
      type: Boolean,
      default: false,
    },

    disableHr: {
      type: Boolean,
      default: false,
    },

    leftButtons: {
      type: Boolean,
      default: false,
    },

    disableMarginBottomButton: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    getDisableButtonClear() {
      return this.disableButtonClear
    },

    getDisableButtonSearch() {
      return this.disableButtonSearch
    },
  },
  methods: {
    actionSearch() {
      this.$emit('actionSearch')
    },

    actionClear() {
      this.$emit('actionClear')
    },
  },
}
</script>

<style scoped lang="scss">
.action-buttons {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 30px;
  margin-bottom: 40px;
}

.m-button-icon {
  margin-right: 5px;
}

hr {
  margin-bottom: 20px
}

.disable-margin-bottom {
  margin-bottom: 0px !important;
}

@media (max-width: 400px) {
  .action-buttons {
    display: flex;
    flex-direction: column;

    button {
      width: 100%;
      margin-bottom: 15px;
    }
  }

  .button-plus {
    width: 100%;
  }
}
</style>
