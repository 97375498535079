<template>
     <b-modal
        id="confirm-delete"
        no-close-on-backdrop
        no-close-on-esc
        hide-header
        hide-footer
        centered
        v-model="modalShow"
        size="md"
    >
        <div class="d-flex flex-column align-items-center justify-center-center my-2 text-center">
            <feather-icon id="modal-confirm" icon="AlertTriangleIcon" size="80" class="icon"/>
            <p class="text-title">
                <strong>Atenção: </strong>Há empresas vinculadas.
            </p>
            <p class="text">
                Antes de excluir este grupo, desvincule todas as empresas associadas a ele.
            </p>
            <div class="d-flex align-items-center justify-center-center mt-1">
                <b-button variant="btn custom-outline-warning" class="mr-2" @click="redirectToEdit">
                    <span>Editar Grupo</span>
                </b-button>
                <b-button variant="btn custom-outline-red" @click="cancel">
                    <span>Cancelar</span>
                </b-button>
            </div>
        </div>
    </b-modal>
</template>

<script>
import { BModal, BButton, BRow, BCol } from 'bootstrap-vue';
export default {
    props: {
        modalShow: {
            required: true,
            type: Boolean
        },
        idGroup: {
            required: true
        }
    },

    components: {
        BModal, BButton, BRow, BCol
    },

    methods: {
        redirectToEdit() {
            this.$router.push(
                {
                    name: 'edit-company-group',
                    params: {
                        idGrupo: this.idGroup
                    }
                }
            );
        },
        cancel() {
            this.$emit('cancelModal', true)
        }
    }
}

</script>

<style>
.icon {
  color: #FF9110;
  margin-bottom: 18px;
}

.text {
    font-size: 14px;
    line-height: 21px;
    max-width: 100%;
}

.text-title {
    font-size: 21px;
    line-height: 21px;
    max-width: 100%;
    margin-bottom: 20px;
}
</style>