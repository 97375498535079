<template>
  <div class="content-wrapper">
    <GroupDeletedSuccessModal
      :modal-show="modalSuccess.showModal"
      :group="groupDeleted"
      @confirm="confirmGroupDeletedSuccess"
    />
    <DeleteModalGroupError
      :modal-show="modalShow"
      :id-group="idGroup"
      @cancelModal="cancelModalDeleteGroup"
    />

    <ConfirmDeleteModalGroup
      :modal-show="modalConfirm.showModal"
      @confirmDelete="deleteGroup"
      @cancelDelete="closeModalConfirm"
    />

    <PageHeader
      screen-name="Grupo de Empresas"
      :link-items="linkItems"
    />

    <main class="card hidden_overflow_tabela px-1">
      <b-row class="align-items-center">
        <b-col class="pt-2 pl-3">
          <b-button
            variant="primary-button"
            @click="redirectToRegister()"
          >
            <feather-icon
              icon="PlusIcon"
              class="mr-50"
            />
            <span class="align-middle">Criar novo grupo</span>
          </b-button>
        </b-col>
      </b-row>
      <hr>
      <FilterGroupList
        :filter="filter"
        @filterTable="loadScheduleData"
      />

      <div
        v-if="loadingData"
        class="d-flex justify-content-center my-3"
      >
        <div
          class="spinner-border text-custom-blue"
          role="status"
        >
          <span class="sr-only">Loading...</span>
        </div>
      </div>
      <b-overlay
        :show="loading"
        rounded="lg"
        opacity="0.6"
        class="w-100"
      >
        <section v-if="!loadingData">
          <b-table
            id="tableEmpresa"
            responsive
            :sticky-column="true"
            :busy.sync="filtering"
            :fields="table.fields"
            :items="table.items"
            no-local-sorting
            :no-sort-reset="true"
            @sort-changed="ordenarColuna"
          >
            <template #cell(descricao)="row">
              <div class="comprimento_maximo_celulas">
                <div
                  v-b-tooltip
                  class="comprimento_maximo_celulas"
                  :title="row.item.descricao > lengthColumnMaximo ? row.item.descricao : null"
                >
                  {{ row.item.descricao }}
                </div>
              </div>
            </template>
            <template #cell(descricao_departamento_administrativo)="row">
              <span
                v-b-tooltip
                class="comprimento_maximo_celulas"
                :title="row.item.descricao_departamento_administrativo > lengthColumnMaximo ? row.item.descricao_departamento_administrativo : null"
              >
                {{ row.item.descricao_departamento_administrativo ? row.item.descricao_departamento_administrativo : '-' }}
              </span>
            </template>
            <template #cell(descricao_departamento_relacionamento)="row">
              <div class="comprimento_maximo_celulas">
                <div
                  v-b-tooltip
                  :title="row.item.descricao_departamento_relacionamento > lengthColumnMaximo ? row.item.descricao_departamento_relacionamento : null"
                >
                  {{ row.item.descricao_departamento_relacionamento ? row.item.descricao_departamento_relacionamento : '-' }}
                </div>

              </div>
            </template>
            <template #cell(descricao_departamento_tecnico)="row">
              <span
                v-b-tooltip
                class="comprimento_maximo_celulas"
                :title="row.item.descricao_departamento_tecnico > lengthColumnMaximo ? row.item.descricao_departamento_tecnico : null"
              >
                {{ row.item.descricao_departamento_tecnico ? row.item.descricao_departamento_tecnico : '-' }}
              </span>
            </template>
            <template #cell(acoes)="row">
              <feather-icon
                id="view-group"
                role="button"
                class="text-custom-blue mr-1"
                icon="EyeIcon"
                size="18"
                @click.prevent="redirectToView(row.item.id_grupo)"
              />
              <feather-icon
                id="edit-group"
                role="button"
                class="text-custom-blue mr-1"
                icon="EditIcon"
                size="18"
                @click.prevent="redirectToEdit(row.item.id_grupo)"
              />
              <feather-icon
                id="edit-group"
                role="button"
                class="text-custom-blue"
                icon="TrashIcon"
                size="18"
                @click.prevent="verifyIfGroupCanExcluded(row.item)"
              />
            </template>
          </b-table>
          <div class="pb-2 px-1">
            <span
              v-if="withoutGrupo"
              class="ml-1"
            >Nenhum grupo de empresas encontrado</span>
            <b-col
              sm="12"
              class="mt-2"
            >
              <CustomPagination
                :paginacao="paginationData"
                @page-cliked="updateCurrentPage"
              />
            </b-col>
          </div>
        </section>
      </b-overlay>
    </main>
  </div>
</template>

<script>
import {
  BForm, BCol, BRow, BFormGroup, BFormInput, BButton, BTable, BFormDatepicker, BInputGroup, BInputGroupAppend, BOverlay,
} from 'bootstrap-vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import vSelect from 'vue-select'
import FilterGroupList from '@/views/custom-pages/GrupoEmpresa/Components/FilterGroupList.vue'
import CustomPagination from '@/views/components/custom/pagination/CustomPagination.vue'
import TableFilter from '@/views/components/custom/table-filter-section/TableFilter.vue'
import DeleteModalGroupError from './Components/DeleteModalGroupError.vue'
import ConfirmModal from '@/views/components/custom/modals/ConfirmModal.vue'
import ConfirmDeleteModalGroup from './Components/ConfirmDeleteModalGroup.vue'
import GroupDeletedSuccessModal from './Components/GroupDeletedSuccessModal.vue'
import { actions, subjects } from '@/libs/acl/rules'
import PageHeader from '@/views/components/custom/page-header/PageHeader.vue'
import Filters from '@/views/components/custom/filters/Filters.vue'

export default {
  title: 'Grupos de Empresas',

  components: {
    BForm,
    BCol,
    BRow,
    BFormGroup,
    BFormInput,
    BButton,
    BTable,
    BFormDatepicker,
    BInputGroup,
    BInputGroupAppend,
    CustomPagination,
    DeleteModalGroupError,
    ValidationObserver,
    ValidationProvider,
    vSelect,
    BOverlay,
    ConfirmDeleteModalGroup,
    TableFilter,
    ConfirmModal,
    GroupDeletedSuccessModal,
    PageHeader,
    Filters,
    FilterGroupList,
  },

  data() {
    return {
      linkItems: [
        {
          name: 'Base Nacional',
          active: true,
        },
        {
          name: 'Grupo de Empresas',
          routeName: 'create-company-group',
          active: false,
        },
      ],
      filtering: false,
      filter: false,
      loadingData: false,
      paginationData: {
        currentPage: 1,
        totalLines: 0,
        fromLine: 0,
        toLine: 0,
        defaultSize: 10,
      },
      table: {
        fields: [
          {
            key: 'descricao', label: 'GRUPO', sortable: true, class: 'width_columns width_columns_header width_columns_header_company',
          },
          {
            key: 'descricao_departamento_administrativo', label: 'ADMINISTRATIVO E FINANCEIRO', sortable: true, class: 'width_columns width_columns_header',
          },
          {
            key: 'descricao_departamento_relacionamento', label: 'RELACIONAMENTO', sortable: true, class: 'width_columns_small width_columns_header',
          },
          {
            key: 'descricao_departamento_tecnico', label: 'TÉCNICO', sortable: true, class: 'width_columns width_columns_header',
          },
          {
            key: 'acoes', label: 'AÇÕES', sortable: false, class: 'width_columns_small width_columns_header',
          },
        ],
        items: [],
        optionsSize: [10, 25, 50, 100],
        columnOrder: 'descricao',
        order: 'asc',
      },
      withoutGrupo: false,
      lengthColumnMaximo: 28,
      modalShow: false,
      idGroup: null,
      groupDeleted: '',
      loading: false,
      modalConfirm: {
        showModal: false,
        typeModal: 'danger',
      },
      modalSuccess: {
        showModal: false,
      },
    }
  },

  mounted() {
    this.verifyPermission()
  },

  methods: {
    updateCurrentPage(page) {
      this.paginationData.currentPage = page
      this.filtering = true
      this.filter = !this.filter
    },

    ordenarColuna(sort) {
      this.table.columnOrder = sort.sortBy
      this.table.order = sort.sortDesc ? 'desc' : 'asc'
      this.filtering = true
      this.loadScheduleData()
    },

    loadScheduleData(filterData = null) {
      this.loading = true
      this.paginationData.defaultSize = filterData ? filterData.defaultSize : 10
      this.searchParams = filterData ? filterData : this.searchParams
      if (filterData != null) {
        if (filterData.hasOwnProperty('currentPage')) {
          this.paginationData.currentPage = filterData.currentPage
        }
      }

      let parameters
      if (filterData) {
        parameters = {
          descricao: filterData.nome_grupo ?? filterData.nome_grupo,
          id_dr_administrativo_financeiro: filterData.id_dr_administrativo_financeiro ?? filterData.id_dr_administrativo_financeiro,
          id_dr_relacionamento: filterData.id_dr_relacionamento ?? filterData.id_dr_relacionamento,
          id_dr_tecnico: filterData.id_dr_tecnico ?? filterData.id_dr_tecnico,
          pagina: filterData.pagina ? filterData.pagina : this.paginationData.currentPage,
          colunaNome: this.table.columnOrder,
          colunaOrdem: this.table.order ? this.table.order : 'asc',
          porPagina: filterData.porPagina ?? filterData.porPagina,
        }
      } else {
        parameters = {
          descricao: this.searchParams.nome_grupo ?? this.searchParams.nome_grupo,
          id_dr_administrativo_financeiro: this.searchParams.id_dr_administrativo_financeiro ?? this.searchParams.id_dr_administrativo_financeiro,
          id_dr_relacionamento: this.searchParams.id_dr_relacionamento ?? this.searchParams.id_dr_relacionamento,
          id_dr_tecnico: this.searchParams.id_dr_tecnico ?? this.searchParams.id_dr_tecnico,
          pagina: this.searchParams.pagina ? this.searchParams.pagina : this.paginationData.currentPage,
          colunaOrdem: this.table.order,
          colunaNome: this.table.columnOrder,
          porPagina: this.paginationData.porPagina,
        }
      }

      this.$http.get(this.$api.getAndPostGrupo(), { params: parameters }).then(({ data }) => {
        this.table.items = data.data
        this.withoutGrupo = false

        if (data.length == 0) {
          this.withoutGrupo = true
        }

        this.loading = false
        this.filtering = false
        this.startPagination(data)
      })
    },

    startPagination(data) {
      this.paginationData.currentPage = data.current_page
      this.paginationData.totalLines = data.total
      this.paginationData.fromLine = data.from
      this.paginationData.toLine = data.to
    },

    verifyIfGroupCanExcluded(group) {
      this.loading = true
      this.idGroup = group.id_grupo
      if (group.operador.length == 0) {
        this.openModalConfirm()
      } else {
        this.modalShow = true
      }
      this.loading = false
    },

    deleteGroup() {
      this.$http.delete(this.$api.getAndPutAndDeleteGrupo(this.idGroup)).then(data => {
        this.loading = false
        this.modalConfirm.showModal = false
        this.modalSuccess.showModal = true
        this.groupDeleted = data.data.descricao
        this.loadScheduleData()
      }).catch(() => {
        this.openModalErrorDelete()
        this.loading = false
      })
    },

    openModalConfirm() {
      this.modalConfirm.showModal = true
      this.loading = false
    },

    cancelModalDeleteGroup() {
      this.modalShow = false
      this.loading = false
    },

    closeModalConfirm() {
      this.modalConfirm.showModal = false
      this.loading = false
    },

    confirmGroupDeletedSuccess() {
      this.modalSuccess.showModal = false
    },

    openModalErrorDelete() {
      this.$swal({
        title: 'Ocorreu um erro.',
        icon: 'warning',
        iconColor: 'red',
        html: '<div>Ocorreu um erro ao tentar deletar o grupo.</div>',
        confirmButtonText: 'Ok',
        customClass: {
          confirmButton: 'btn btn-custom-blue',
        },
        buttonsStyling: false,
      })
    },

    redirectToRegister() {
      this.$router.push({ name: 'create-company-group' })
    },

    redirectToEdit(id_grupo) {
      this.$router.push(
        {
          name: 'edit-company-group',
          params: {
            idGrupo: id_grupo,
          },
        },
      )
    },

    redirectToView(id_grupo) {
      this.$router.push(
        {
          name: 'view-company-group',
          params: {
            idGrupo: id_grupo,
          },
        },
      )
    },

    verifyPermission() {
      return this.$can(actions.VISUALIZAR, subjects.ATUACAO_BASE_NACIONAL)
    },
  },
}
</script>
