<template>

  <main class="card hidden_overflow_tabela px-1">
    <validation-observer>
      <b-form>
        <b-row class="align-items-center">
          <b-col
            sm="6"
            lg="3"
            class="px-2"
          >
            <b-form-group
              label="Nome do grupo"
              label-for="groupName"
              label-class="font_size_label"
            >
              <validation-provider
                #default="{errors}"
                name="groupName"
              >
                <b-form-input
                  id="groupName"
                  v-model="form.groupName"
                  placeholder="Insira o nome do grupo"
                  autocomplete="off"
                  maxlength="255"
                >
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-input>

              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col
            sm="6"
            lg="3"
            class="px-2"
          >
            <b-form-group
              label="Administrativo e Financeiro"
              label-for="financial_administative"
              label-class="font_size_label"
            >
              <validation-provider
                #default="{errors}"
                name="Administrativo e Financeiro"
              >
                <v-select
                  id="financial_administrative"
                  v-model="form.financial_administrative"
                  :options="optionsDr"
                  placeholder="Selecione a DR"
                  label="descricao"
                  item-text="financial_administrative"
                  item-value="financial_administrative"
                  :clearable="false"
                  @input="getOptionsDr"
                >
                  <span slot="no-options">Nenhuma opção selecionável.</span>
                  <small class="text-danger">{{ errors[0] }}</small>
                </v-select>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col
            sm="6"
            lg="3"
            class="px-2"
          >
            <b-form-group
              label="Relacionamento"
              label-for="relationship"
              label-class="font_size_label"
            >
              <validation-provider
                #default="{errors}"
                name="Relacionamento"
              >
                <v-select
                  id="relationship"
                  v-model="form.relationship"
                  :options="optionsDr"
                  placeholder="Selecione a DR"
                  label="descricao"
                  item-text="relationship"
                  item-value="relationship"
                  :clearable="false"
                  @input="getOptionsDr"
                >
                  <span slot="no-options">Nenhuma opção selecionável.</span>
                  <small class="text-danger">{{ errors[0] }}</small>
                </v-select>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col
            sm="6"
            lg="3"
            class="px-2"
          >
            <b-form-group
              label="Técnico"
              label-for="technical"
              label-class="font_size_label"
            >
              <validation-provider
                #default="{errors}"
                name="Técnico"
              >
                <v-select
                  id="technical"
                  v-model="form.technical"
                  :options="optionsDr"
                  placeholder="Selecione a DR"
                  label="descricao"
                  item-text="technical"
                  item-value="technical"
                  :clearable="false"
                  @input="getOptionsDr"
                >
                  <span slot="no-options">Nenhuma opção selecionável.</span>
                  <small class="text-danger">{{ errors[0] }}</small>
                </v-select>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
    <Filters
      @actionSearch="search"
      @actionClear="clear"
    />

    <b-row>
      <b-col
        lg="5"
        md="5"
        sm="5"
        class="col-12 mb-1 mb-sm-0"
      >
        <div class="d-flex justify-center-center align-items-center">
          <span
            class="mr-1"
            for="tableSize"
          >Mostrar</span>
          <v-select
            id="tableSize"
            v-model="defaultSize"
            :options="optionsSize"
            :clearable="false"
            class="customizeDifferentSpace"
            @input="fireEventFilter(false, true)"
          >
            <span slot="no-options">Nenhuma opção selecionável.</span>
          </v-select>
        </div>
      </b-col>
    </b-row>
  </main>

</template>

<script>

import { ValidationObserver, ValidationProvider } from 'vee-validate'
import {
  BForm, BCol, BRow, BFormGroup, BFormInput,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { setListFilter } from '@core/utils/store/setStore'
import Filters from '@/views/components/custom/filters/Filters.vue'

export default {
  components: {
    ValidationObserver,
    ValidationProvider,
    Filters,
    vSelect,
    BForm,
    BCol,
    BRow,
    BFormGroup,
    BFormInput,
  },
  props: {
    filter: {
      required: true,
      type: Boolean,
    },
  },
  data() {
    return {
      defaultSize: 10,
      optionsSize: [10, 25, 50, 100],
      searchTerm: '',
      timeID: null,
      searchFilter: null,
      form: {
        groupName: '',
        financial_administrative: '',
        relationship: '',
        technical: '',
      },
      optionsDr: [],
    }
  },
  watch: {
    filter() {
      this.fireEventFilter()
    },

    defaultSize() {
      this.$emit('defaultSizeChanged')
    },
  },
  mounted() {
    this.getOptionsDr()
    this.fireEventFilter(true, false)
  },
  methods: {
    getOptionsDr() {
      this.$http.get(this.$api.getDepartmentNational()).then(departaments => {
        this.optionsDr = departaments.data
      })
    },

    fireEventFilter(first = false, changeSize = false) {
      const listFilter = {
        nome_grupo: this.form.groupName,
        id_dr_administrativo_financeiro: this.form.financial_administrative.id_departamento,
        id_dr_relacionamento: this.form.relationship.id_departamento,
        id_dr_tecnico: this.form.technical.id_departamento,
        defaultSize: this.defaultSize,
        route: this.$router.currentRoute.name,
      }

      if (changeSize) { listFilter.currentPage = 1 }
      if (!first) { setListFilter(listFilter) }

      this.searchFilter = {
        porPagina: this.defaultSize,
        route: this.$router.currentRoute.name,
        nome_grupo: this.form.groupName,
        id_dr_administrativo_financeiro: this.form.financial_administrative.id_departamento,
        id_dr_relacionamento: this.form.relationship.id_departamento,
        id_dr_tecnico: this.form.technical.id_departamento,
        defaultSize: this.defaultSize,
      }
      this.$emit('filterTable', this.searchFilter)
    },

    search() {
      this.searchFilter = {
        defaultSize: this.defaultSize,
        route: this.$router.currentRoute.name,
        nome_grupo: this.form.groupName,
        id_dr_administrativo_financeiro: this.form.financial_administrative.id_departamento,
        id_dr_relacionamento: this.form.relationship.id_departamento,
        id_dr_tecnico: this.form.technical.id_departamento,
        clear: false,
        porPagina: this.defaultSize,
        pagina: 1,
      }

      this.$emit('filterTable', this.searchFilter)
    },

    clear() {
      this.form.financial_administrative = ''
      this.form.groupName = ''
      this.form.relationship = ''
      this.form.technical = ''
      this.searchFilter = {
        defaultSize: this.defaultSize,
        route: this.$router.currentRoute.name,
        pagina: 1,
        porPagina: 10,
        clear: true,
      }

      this.$emit('filterTable', this.searchFilter)
    },
  },
}

</script>