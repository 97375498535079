var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('main',{staticClass:"card hidden_overflow_tabela px-1"},[_c('validation-observer',[_c('b-form',[_c('b-row',{staticClass:"align-items-center"},[_c('b-col',{staticClass:"px-2",attrs:{"sm":"6","lg":"3"}},[_c('b-form-group',{attrs:{"label":"Nome do grupo","label-for":"groupName","label-class":"font_size_label"}},[_c('validation-provider',{attrs:{"name":"groupName"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"groupName","placeholder":"Insira o nome do grupo","autocomplete":"off","maxlength":"255"},model:{value:(_vm.form.groupName),callback:function ($$v) {_vm.$set(_vm.form, "groupName", $$v)},expression:"form.groupName"}},[_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])])]}}])})],1)],1),_c('b-col',{staticClass:"px-2",attrs:{"sm":"6","lg":"3"}},[_c('b-form-group',{attrs:{"label":"Administrativo e Financeiro","label-for":"financial_administative","label-class":"font_size_label"}},[_c('validation-provider',{attrs:{"name":"Administrativo e Financeiro"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"id":"financial_administrative","options":_vm.optionsDr,"placeholder":"Selecione a DR","label":"descricao","item-text":"financial_administrative","item-value":"financial_administrative","clearable":false},on:{"input":_vm.getOptionsDr},model:{value:(_vm.form.financial_administrative),callback:function ($$v) {_vm.$set(_vm.form, "financial_administrative", $$v)},expression:"form.financial_administrative"}},[_c('span',{attrs:{"slot":"no-options"},slot:"no-options"},[_vm._v("Nenhuma opção selecionável.")]),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])])]}}])})],1)],1),_c('b-col',{staticClass:"px-2",attrs:{"sm":"6","lg":"3"}},[_c('b-form-group',{attrs:{"label":"Relacionamento","label-for":"relationship","label-class":"font_size_label"}},[_c('validation-provider',{attrs:{"name":"Relacionamento"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"id":"relationship","options":_vm.optionsDr,"placeholder":"Selecione a DR","label":"descricao","item-text":"relationship","item-value":"relationship","clearable":false},on:{"input":_vm.getOptionsDr},model:{value:(_vm.form.relationship),callback:function ($$v) {_vm.$set(_vm.form, "relationship", $$v)},expression:"form.relationship"}},[_c('span',{attrs:{"slot":"no-options"},slot:"no-options"},[_vm._v("Nenhuma opção selecionável.")]),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])])]}}])})],1)],1),_c('b-col',{staticClass:"px-2",attrs:{"sm":"6","lg":"3"}},[_c('b-form-group',{attrs:{"label":"Técnico","label-for":"technical","label-class":"font_size_label"}},[_c('validation-provider',{attrs:{"name":"Técnico"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"id":"technical","options":_vm.optionsDr,"placeholder":"Selecione a DR","label":"descricao","item-text":"technical","item-value":"technical","clearable":false},on:{"input":_vm.getOptionsDr},model:{value:(_vm.form.technical),callback:function ($$v) {_vm.$set(_vm.form, "technical", $$v)},expression:"form.technical"}},[_c('span',{attrs:{"slot":"no-options"},slot:"no-options"},[_vm._v("Nenhuma opção selecionável.")]),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])])]}}])})],1)],1)],1)],1)],1),_c('Filters',{on:{"actionSearch":_vm.search,"actionClear":_vm.clear}}),_c('b-row',[_c('b-col',{staticClass:"col-12 mb-1 mb-sm-0",attrs:{"lg":"5","md":"5","sm":"5"}},[_c('div',{staticClass:"d-flex justify-center-center align-items-center"},[_c('span',{staticClass:"mr-1",attrs:{"for":"tableSize"}},[_vm._v("Mostrar")]),_c('v-select',{staticClass:"customizeDifferentSpace",attrs:{"id":"tableSize","options":_vm.optionsSize,"clearable":false},on:{"input":function($event){return _vm.fireEventFilter(false, true)}},model:{value:(_vm.defaultSize),callback:function ($$v) {_vm.defaultSize=$$v},expression:"defaultSize"}},[_c('span',{attrs:{"slot":"no-options"},slot:"no-options"},[_vm._v("Nenhuma opção selecionável.")])])],1)])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }